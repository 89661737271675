import React from 'react';
import reactLogo from "./../assets/img/cc-logo.png";
import Header from './Header';


const MaintenancePage = () => {
  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    textAlign: 'center',
    backgroundColor: '#f8f9fa',
    fontFamily: 'Arial, sans-serif',
    color: '#333',
  };

  const headerStyle = {
    position: 'absolute',
    top: '0',
    width: '100%',
    padding: '1rem 0',
    backgroundColor: '#ffffff', // White background
    color: '#fff',
    textAlign: 'center',
    boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)',
  };

  const logoStyle = {
    height: '50px',
  };

  const contentContainerStyle = {
    flex: '1',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  };

  const headingStyle = {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    marginBottom: '1rem',
  };

  const messageStyle = {
    fontSize: '1.2rem',
    marginBottom: '2rem',
    maxWidth: '600px',
    lineHeight: '1.5',
  };

  const loaderStyle = {
    width: '50px',
    height: '50px',
    border: '5px solid #ccc',
    borderTop: '5px solid #007bff',
    borderRadius: '50%',
    animation: 'spin 1s linear infinite',
    marginBottom: '1.5rem',
  };

  return (
    <div style={pageStyle}>
      {/* Header with Logo */}
      {/* <div style={headerStyle}>
        <img src={reactLogo} alt="Cheap Caribbean vacation" />
      </div> */}
      <Header enableMaintance={true}/>
      {/* Main Content */}
      <div style={contentContainerStyle}>
        {/* <div style={loaderStyle} /> */}
        <h1 style={headingStyle}>We'll Be Back Soon!</h1>
        <p style={messageStyle}>
          Our website is currently undergoing scheduled maintenance. We apologize for the
          inconvenience and appreciate your patience. Please check back after{' '}
          <strong>January 25th, 11:00 AM CST</strong>.
        </p>
      </div>
    </div>
  );
};

export default MaintenancePage;
